<template>
  <div ref="container" class="importantKey w100">
    <Title :isShowSwitch="isShowSwitch" @changeSwitch="changeSwitch"  @curveFun="curveFun">农村卫生</Title>
    <div v-if="cutIdx == 0">
      <TotalAnalysis style="marginBottom: 0" :value="valueByLevel" :patter="tit"></TotalAnalysis>
      <div class="m_xares">
        <div class="line" v-for="(v, i) in essenFun" :key="i">
          <p>{{ v.name }}</p>
          <div class="value"><countTo :startVal="0" :endVal="v.value" :duration="1000"></countTo></div>
        </div>
      </div>
    </div>
    <div v-if="cutIdx == 1">
      <div class="m_xour">
        <em :class="{'on' : ourIdx == 0}" @click="ourFun(0)">机构总数</em>
        <em :class="{'on' : ourIdx == 1}" @click="ourFun(1)">四类机构卫生人员总数</em>
        <em :class="{'on' : ourIdx == 2}" @click="ourFun(2)">行政村总数</em>
      </div>
      <BaseEchart :option="option" style="margin-top: -10px; width: 100%; height: 325px"></BaseEchart>
    </div>
    <div class="m_xtment" v-if="cutIdx == 2">
      <div class="lump">
        <div class="side">
          <img src="@/assets/imgs/lump.png" alt="" />
          <dfn>县级</dfn>
        </div>
        <div class="right">
          <p v-for="(s, t) in areaArrByLevel" :key="t">{{s.name}}<countTo :startVal="0" :endVal="s.num" :duration="1000"></countTo></p>
        </div>
      </div>
      <div class="lump">
        <div class="side">
          <img src="@/assets/imgs/lump.png" alt="" />
          <dfn>乡镇</dfn>
        </div>
        <div class="right">
          <p v-for="(s, t) in vionArrByLevel" :key="t">{{s.name}}<countTo :startVal="0" :endVal="s.num" :duration="1000"></countTo></p>
        </div>
      </div>
    </div>
    <div class="m_xcut">
      <em v-for="(v, i) in cutArr" :key="i" :class="{'on': i == cutIdx}" @click="cutFun(i)">{{v}}</em>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import countTo from 'vue-count-to';
import TotalAnalysis from '../components/TotalAnalysis.vue'
import BaseEchart from '@/components/BaseEchart';
import Title from '../components/Title';
import { fetchRegionSummaryNotations, mapDataNormal } from '../../../request/api';
export default {
  components: {BaseEchart, Title, countTo, TotalAnalysis},
  data() {
    return {
      option: null,
			analysisNum: 40758,
      cutIdx: 0,
      cutArr:['新型农村合作医疗', '农村三级医疗效劳体系', '农村医疗效劳'],
      tit: {
        type: 2,
        title: '新型合作医疗开展数（县）'
      },
      essenArr: [
        {value: 1580, name: '参合人口数'},
        {value: 5140, name: '参合率'},
        {value: 1420, name: '筹资总额'},
        {value: 5240, name: '人均筹资'},
        {value: 2000, name: '新农合基金支出'},
        {value: 6340, name: '补偿支出收益人数'},
      ],
      areaArr: [{name:'县级医院诊疗人数', num: 14230}, {name:'入院人数', num: 12002}, {name:'病床使用率', num: 24009}],
      vionArr: [{name:'乡镇卫生院诊疗人数', num: 2406}, {name:'入院人数', num: 1980}, {name:'病床使用率', num: 4000}],
      ourIdx: 0,
      imitate: {title: '机构总数', num: 0},
      imitateArr: [
        { value: 0, name: '疾控中心' },
        { value: 0, name: '卫生监督所' },
        { value: 0, name: '其他' }
      ],
      isShowSwitch: true,
      isSwitchOpen: true,
      period: 1,
      itemName: '农村卫生三级医疗劳效体系-疾控中心;农村卫生三级医疗劳效体系-卫生监督所;农村卫生三级医疗劳效体系-其他'
    };
  },
  computed: {
    ...mapState('mapData', ['addressInfo', 'mapLevel', 'mapDataArr']),
    currentMonthPeriod(){
      return this.$store.state.homePage.currentMonthPeriod
    },
    monthPeriod(){
      return this.$store.state.homePage.monthPeriod
    },
		valueByLevel(){
      let num = Math.round(this.analysisNum / (this.mapLevel + 1)/this.period)
      return num
    },
    imitateByLevel(){
      return Math.round(this.imitate.num / (this.mapLevel + 1)/this.period)
    },
    areaArrByLevel(){
      return this.areaArr.map(s => {
        return {
          ...s,
          num: Math.round((s.num / (this.mapLevel + 1))/this.period)
        }
      })
    },
    vionArrByLevel(){
      return this.vionArr.map(s => {
        return {
          ...s,
          num: Math.round((s.num / (this.mapLevel + 1))/this.period)
        }
      })
    },
    essenFun(){
      return this.essenArr.map(s => {
        return {
          ...s,
          value: Math.round((s.value / (this.mapLevel + 1))/this.period)
        }
      })
    },
    imitateArrByLevel(){
      return this.imitateArr.map(s => {
        return {
          ...s,
          value: Math.round((s.value / (this.mapLevel + 1))/this.period)
        }
      })
    },
  },
  watch: {
    mapDataArr: {
      handler(){
        if(this.mapDataArr.length > 0){
          this.selectTime()
        }
      },
      immediate: true
    },
    monthPeriod:{
      handler(){
        this.selectTime()
      }
    }
  },
  methods: {
    cutFun(i) {
      this.cutIdx = i
      let num = 0
      if(i == 0){
        num = this.valueByLevel
      }else if(i == 1){
        num = this.imitate.num
      }else{
        num = this.areaArrByLevel[0].num
      }
      this.$store.state.homePage.block.ruralHealth = num
    },
    async init() {
      let arry = []
      if(this.cutIdx == 0){
        this.$store.state.homePage.block.ruralHealth = this.valueByLevel
      }
      if(this.ourIdx == 0){
        let data = await fetchRegionSummaryNotations({itemName: this.itemName, fullTime: this.isSwitchOpen})
        arry = mapDataNormal(this.imitateArr, data?.data?.collection || [])
      }else{
        arry = this.imitateArrByLevel
      }
      let num = 0
      arry.map(v => {
        num += v.value
      })
      this.imitate.num = num
      this.option = {
        color: ['#1283E3', '#03FFC7', '#FBD25B', '#00FFFF'],
        tooltip: {
          trigger: 'item',
        },
        legend: {
          bottom: 0,
          padding: [0, 20, 10, 0],
          formatter: function (name) {
            return '{title|' + name + '}';
          },
          icon: 'circle',
          itemWidth: 6,
          itemHeight: 6,
          textStyle: {
            color: 'white',
            backgroundColor: 'transparent',
            rich: {
              a: {
                width: 80,
                fontSize: 14
              },
            },
          },
        },
        grid: {
          top: 0,
        },
        graphic: {
          elements: [
            {
              type: 'circle',
              shape: {
                r: 130,
              },
              style: {
                stroke: '#307BE3',
                lineWidth: 1,
              },
              left: 'center',
              top: 12,
            },
            {
              type: 'circle',
              shape: {
                r: 78,
              },
              style: {
                stroke: '#307BE3',
                lineWidth: 1,
              },
              left: 'center',
              top: 65,
            },
            {
              type: 'image',
              style: {
                image: require('../assets/pie-bg2.jpg'),
                width: 128,
                height: 128,
              },
              left: 'center',
              top: 80,
            },
            {
              type: 'text',
              left: '43.5%',
              top: 120,
              style: {
                fill: '#ffffff',
                text: this.imitate.title,
                font: '16px sans-serif',
                textAlign: 'center',

              },
            },
            {
              type: 'text',
              left: 'middle',
              bounding: 'raw',
              left: 'center',
              top: 150,
              style: {
                fill: '#00F7F9',
                // text: this.imitateByLevel,
                text: this.imitate.num,
                font: '28px sans-serif',
                textAlign: 'center',
              },
            },
          ],
        },
        series: [
          {
            type: 'pie',
            top: -40,
            left: 'center',
            width: 240,
            radius: ['80%', '100%'],
            avoidLabelOverlap: false,
            itemStyle: {
              borderRadius: 20,
              borderColor: '#020C1D',
              borderWidth: 3
            },
            label: {
              show: false,
              position: 'center',
            },
            labelLine: {
              show: false,
            },
            data: [...arry, {
              tooltip: { formatter: () => '' },
              itemStyle: { normal: { color: 'rgba(0, 0, 0, 0)' } },
            }],
          },
        ],
      };
    },
    ourFun(k){
      this.ourIdx = k
      this.init()
    },
    changeSwitch(val){
      this.isSwitchOpen=val
      this.selectTime()
    },
    curveFun(name){
      console.log(name)
    },
    selectTime(){
      if(this.isSwitchOpen){
        this.period = this.monthPeriod
      }else{
        this.period = this.currentMonthPeriod
      }
      this.init()
    }
  }
};
</script>
<style lang="scss" scoped>
.importantKey {
  border: 2px solid #264471;
  box-sizing: border-box;
  position: relative;
  background: rgba(5, 41, 92, .6);
  border: 2px solid rgba(17, 94, 205, .2);
  .nullData {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.m_xcut{
  height: 35px;
  display: flex;
  align-items: center;
  border-top: 1px solid #213861;
  em{
    flex: 1;
    font-size: 14px;
    font-weight: bold;
    color: #FFFFFF;
    font-style: normal;
    text-align: center;
    line-height: 35px;
    position: relative;
    cursor: pointer;
  }
}
.m_xcut em:not(:first-child)::before{
  position: absolute;
  content: '';
  left: 0;
  width: 1px;
  height: 12px;
  top:50%;
  margin-top: -6px;
  background: #213861;
}
.m_xcut .on{
  color:#00F0FF;
}
.m_xares{
	padding: 0 5px 15px;
	text-align: center;
	.line{
		width: 47%;
		height: 70px;
		background: rgba(28, 135, 255, .1);
		margin: 16px 1.5% 0;
    display: inline-block;
	}
	p{
		font-size: 16px;
		color:#fff;
		padding: 14px 0 0;
	}
  .crew{
    display: inline-block;
    width: 76px;
    height: 76px;
    margin: 30px 0 10px;
  }
  .value{
    font-size: 20px;
    font-weight: 500;
    color: #00FFFF;
  }
}
.m_xtment{
  padding: 10px 15px 6px;
  .side{
    width: 128px;
    height: 140px;
    background: rgba(28, 135, 255, .1);
    float: left;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    dfn{
      position: absolute;
      width: 42px;
      height: 20px;
      background: #1C87FF;
      left:0;
      top:0;
      text-align: center;
      line-height: 20px;
      font-size: 14px;
      color:#fff;
      font-style: normal;
    }
  }
}
.m_xtment .lump{
  overflow: hidden;
  margin: 20px 0;
}
.m_xtment .right{
  margin-left: 129px;
  p{
    height: 46px;
    background: rgba(28, 135, 255, .1);
    padding: 0 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1px;
    font-size: 14px;
    color:#fff;
  }
  span{
    font-size: 20px;
    font-weight: 500;
    color: #00FFFF;
  }
}
.m_xour{
  display: flex;
  em{
    font-style: normal;
    flex:1;
    text-align: center;
    height: 44px;
    line-height: 44px;
    cursor: pointer;
  }
  .on{
    color:#00F0FF;
    text-decoration: underline;
  }
}
</style>