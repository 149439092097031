<template>
  <div ref="container" class="importantKey w100">
    <Title :isShowSwitch="isShowSwitch" @changeSwitch="changeSwitch">病人医药费用</Title>
    <div class="m_xient" v-if="cutIdx == 0">
      <h5 v-for="(v, i) in cardDataByLevel" :key="i">
        <em><img src="@/assets/imgs/money.png" alt="" />{{ v.name }}</em>
        <countTo :startVal="0" :endVal="v.value" :duration="1000"></countTo>
      </h5>
    </div>
    <div class="m_xient" v-if="cutIdx == 1">
      <h5 v-for="(v, i) in equalArrByLevel" :key="i">
        <em><img src="@/assets/imgs/money.png" alt="" />{{ v.name }}</em>
        <countTo :startVal="0" :endVal="v.value" :duration="1000"></countTo>
      </h5>
    </div>
    <div class="m_xient" v-if="cutIdx == 2">
      <div class="rows" v-for="(v, i) in imitateArrLevel" :key="i">
        <h5>
          <em><img src="@/assets/imgs/money.png" alt="" />{{ v.name }}</em>
          <span>
            <countTo :startVal="0" :endVal="v.value" :duration="1000"></countTo>/
            <countTo :startVal="0" :endVal="v.per" :duration="1000"></countTo>%
          </span>
        </h5>
        <p>
          药费比上年增加
          <countTo :startVal="0" :endVal="v.big" :duration="1000" class="small"></countTo>
          <img src="@/assets/imgs/up.png" alt="" />
          <dfn>药费比重上升</dfn>
          <countTo :startVal="0" :endVal="v.up" :duration="1000" class="small"></countTo>%
          <img src="@/assets/imgs/up.png" alt="" />
        </p>
      </div>
      
    </div>
    <div class="m_xcut">
      <em v-for="(v, i) in cutArr" :key="i" :class="{'on': i == cutIdx}" @click="cutFun(i)">{{v}}</em>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import countTo from 'vue-count-to';
import TotalAnalysis from '../components/TotalAnalysis.vue'
import BaseEchart from '@/components/BaseEchart';
import Title from '../components/Title';
export default {
  components: {BaseEchart, Title, countTo, TotalAnalysis},
  data() {
    return {
      option: null,
			analysisNum: 40758,
      cardData: [{ name: '医院门诊病人次均医药费用', value: 15200 }, { name: '社区卫生效劳中心门诊病人次均医药费用', value: 3765 }, { name: '乡镇卫生院门诊病人次均医药费用', value: 825 }],
      equalArr: [{ name: '医院门诊病人次均医药费用', value: 63202 }, { name: '社区卫生效劳中心门诊病人次均医药费用', value: 1265 }, { name: '乡镇卫生院门诊病人次均医药费用', value: 2000 }],
      cutIdx: 0,
      cutArr:['门诊人均费用', '住院人均费用', '药费占比'],
      imitateArr: [
        { value: 1048, name: '医院门诊人次均医药费用', per: 80, big: 95225, up: 14 },
        { value: 735, name: '医院住院病人人均医药费', per: 70, big: 52025, up: 20 },
      ],
      isShowSwitch:true,
      isSwitchOpen:true,
      period:1
    };
  },
  computed: {
    ...mapState('mapData', ['addressInfo', 'mapLevel', 'mapDataArr']),
    cardDataByLevel(){
      return this.cardData.map(s => {
        return {
          ...s,
          value: Math.round((s.value / (this.mapLevel + 1))/this.period)
        }
      })
    },
    imitateArrLevel(){
      return this.imitateArr.map(s => {
        return {
          ...s,
          value: Math.round((s.value / (this.mapLevel + 1))/this.period),
          per: Math.round((s.per -(this.mapLevel + 1))-this.period*2),
          big: Math.round((s.big / (this.mapLevel + 1))/this.period),
          up: Math.round((s.up -(this.mapLevel + 1))-this.period),
        }
      })
    },
    equalArrByLevel(){
      return this.equalArr.map(s => {
        return {
          ...s,
          value: Math.round((s.value / (this.mapLevel + 1))/this.period)
        }
      })
    },
    currentMonthPeriod(){
      return this.$store.state.homePage.currentMonthPeriod
    },
    monthPeriod(){
      return this.$store.state.homePage.monthPeriod
    },
  },
  watch: {
    monthPeriod:{
     handler(){
      this.selectTime()
     },
    }
  },
  mounted() {
    this.selectTime()
  },
  methods: {
    cutFun(i) {
      this.cutIdx = i
      let num = 0;
      if(i == 0){
        num = this.cardDataByLevel[0].value
      }else if(i == 1){
        num = this.equalArrByLevel[0].value
      }else{
        num = this.imitateArrLevel[0].value
      }
      this.$store.state.homePage.block.medExpense = num
    },
    changeSwitch(val){
      this.isSwitchOpen=val
      this.selectTime()
    },
    selectTime(){
      if(this.cutIdx == 0){
        this.$store.state.homePage.block.medExpense = this.cardDataByLevel[0].value
      }
      if(this.isSwitchOpen){
        this.period = this.monthPeriod
      }else{
        this.period = this.currentMonthPeriod
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.importantKey {
  border: 2px solid #264471;
  box-sizing: border-box;
  position: relative;
  background: rgba(5, 41, 92, .6);
  border: 2px solid rgba(17, 94, 205, .2);
}
.m_xcut{
  height: 35px;
  display: flex;
  align-items: center;
  border-top: 1px solid #213861;
  em{
    flex: 1;
    font-size: 14px;
    font-weight: bold;
    color: #FFFFFF;
    font-style: normal;
    text-align: center;
    line-height: 35px;
    position: relative;
    cursor: pointer;
  }
}
.m_xcut em:not(:first-child)::before{
  position: absolute;
  content: '';
  left: 0;
  width: 1px;
  height: 12px;
  top:50%;
  margin-top: -6px;
  background: #213861;
}
.m_xcut .on{
  color:#00F0FF;
}
.m_xient{
  padding: 10px 0;
  h5{
    height: 38px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 16px;
    font-weight: normal;
  }
  em{
    font-style: normal;
    display: flex;
    align-items: center;
    font-size: 16px;
    color:#fff;
  }
  img{
    margin: 2px 10px 0 0;
  }
  span{
    color:#00F0FF;
    font-size: 22px;
  }
  .rows{
    background: rgba(28, 135, 255, .1);
    padding: 2px 6px 6px;
    margin: 0 8px;
    h5{
      height: 30px;
    }
  }
  .rows:first-child{
    margin-bottom: 6px;
  }
  p{
    font-size: 14px;
    padding: 0 10px 0 50px;
    img{
      width: 10px;
      height: 12px;
      margin: 0 0 0 5px;
    }
  }
  .small{
    font-size: 14px;
    color:#fff;
    padding: 0 0 0 12px;
  }
  dfn{
    font-style: normal;
    padding: 0 10px 0 15px;
  }
}
</style>