<template>
  <div ref="container" class="importantKey w100">
    <Title>服务群众统计</Title>
    <div class="nullData" v-if="showNoData" :style="{ height: chartHeight }">
      <img src="@/assets/imgs/noData.png" alt="" />
    </div>
    <div class="cardContainer">
      <StatisticsCard :name="i.name" :value="i.value" :key="i.name" v-for="i in cardDataByLevel">
      </StatisticsCard>
    </div>
    <BaseEchart :option="option" style="height: 280px; width: 100%"></BaseEchart>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { barChart } from '../common'
import { randomByLevel } from '@/views/homePage/common';
import BaseEchart from '@/components/BaseEchart';
import Title from '../components/Title';
import StatisticsCard from '../components/StatisticsCard'

export default {
  components: {
    StatisticsCard,
    BaseEchart,
    Title
  },
  data() {
    return {
      option: null,
      showNoData: false,
      cardData: [{ name: '总人数', value: 830000 }, { name: '老人', value: 1025 }, { name: '妇女', value: 825 }, { name: '儿童', value: 2042 }, { name: '常见病', value: 159 }]
    };
  },
  computed: {
    ...mapState('mapData', ['addressInfo', 'mapLevel', 'mapDataArr']),
    cardDataByLevel() {
      return this.cardData.map(c => {
        return { ...c, value: Math.round(c.value / (this.mapLevel + 1)) }
      })
    },
    chartHeight() {
      if ((this.addressInfo.cityCode + '').length == 12) {
        return document.body.clientHeight - 788 > 400 ? document.body.clientHeight - 788 + 'px' : 400 + 'px';
      } else {
        return document.body.clientHeight - 788 > 365 ? document.body.clientHeight - 788 + 'px' : 365 + 'px';
      }
    },
  },
  watch: {
    mapDataArr() {
      const arrData = this.mapDataArr.map((d) => {
        return { name: d.regionName, value: randomByLevel({ max: 1000, level: this.mapLevel }) };
      })
      this.option = barChart({ xData: arrData.map(a => a.name), yData: arrData.map(a => a.value) }, {max: 1000})
    }
  },
};
</script>

<style lang="scss" scoped>
.importantKey {
  border: 2px solid #264471;
  box-sizing: border-box;
  position: relative;
  background: rgba(5, 41, 92, .6);
  border: 2px solid rgba(17, 94, 205, .2);
  .nullData {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .cardContainer {
    display: flex;
    gap: 8px;
    padding: 8px;
  }
}
</style>